import { Stack, useToast } from "@chakra-ui/react";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router";

import NavBar from "../../../components/nav/NavBar";

const VaultLayout = () => {
  return (
    <Stack
      as="main"
      bgColor="background"
      direction={{ base: "column", lg: "row" }}
      minH={"100vh"}
      h="full"
    >
      <NavBar>
        <Outlet />
      </NavBar>
    </Stack>
  );
};

export default VaultLayout;
